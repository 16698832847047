import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 20 min. `}<meta itemProp="prepTime" content="PT20M" /></h5>
    <h5>{`Cook time: 60 min. `}<meta itemProp="cookTime" content="PT60M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken thighs, 4</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Flour, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Olive oil, 3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 3 cloves</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Tomato paste, 3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Oregano, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Basil, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Red pepper flakes, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken stock, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Heavy cream, 3/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan, 1/2 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sun-dried tomatoes, 1/3 cup</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large pan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Thaw chicken</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop garlic</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Slice sun-dried tomatoes</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Season chicken with salt and pepper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Place flour on a large plate.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Heat oil and butter in pan until butter is melted.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`For each piece of chicken: coat, shake off excess flour, and place into pan. Cook until golden, about 5 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Flip chicken over and cook until the other side is golden, about 4-5 minutes. Add more oil as needed.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Transfer chicken to a plate.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Reduce heat and add garlic. Cook until fragrant.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add tomato paste. Stir until color deepens.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add oregano, basil, and red pepper flakes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Increase heat and add chicken stock.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bring to a simmer and stir occassionally until liquid is reduced by half.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in 1/2 cup of cream.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Stir in parmesan, sun-dried tomatoes, and rest of the cream.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Place chicken back into the pan to heat, about 4 minutes.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      